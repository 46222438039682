<script lang="ts" setup>
import { computed } from "vue";

const breadcrumbs = computed(() => [{ default: "IAM-UsersOverview" }]);
</script>
<template>
  <v-container fluid pa-0 class="app-iam">
    <inn-breadcrumbs :items="breadcrumbs"></inn-breadcrumbs>

    <v-container fluid>
      <header class="app-welcome-message">
        <h2><v-icon>mdi-account-multiple</v-icon> Gebruikers</h2>
      </header>
      <v-row>
        <v-col cols="2" class="sidebar">
          <v-list nav>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-UsersOverview' }">
                <v-list-item-title>Overzicht</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-UsersLogins' }">
                <v-list-item-title>Logins</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col>
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
